import React from "react"
import {graphql} from "gatsby"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import {Col, Container, Media, Row} from "react-bootstrap";
import {FaQuoteLeft} from "react-icons/fa";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <Container fluid={'xl'}>
                <div className={'my-5'}>
            <span className={'title text-uppercase font-weight-bold'}>
                Heerlijk wonen
            </span>
                    <h2>
                        Een zee aan <span className={'text-primary'}>mogelijkheden</span>
                    </h2>
                </div>
                <p className={'lead my-5'} style={{
                    maxWidth: 572
                }}>
                    Vanaf het Wolderwijd vaart u zo de nieuwe Schelpenbuurt in. Een nieuwe waterrijke wijk, gelegen achter het nog in ontwikkeling zijnde Havenkwartier. Veel groen, water en vergezichten, het is wat Zeewolde onderscheidt.
                </p>

                <Row className={'my-5'}>
                    <Col md={{span: 7, offset: 1}} className={'border-left'}>
                        <p className={'mb-0'}>
                            De plek heeft voor iedereen wat te bieden. Van lekker wandelen langs het water, relaxen op de diverse strandjes tot struinen tussen de reeën en konikpaarden in het Horsterwold; het kan allemaal. Ook de centrale ligging maakt van Zeewolde een ideale woonplaats. Binnen no-time bent u in de Randstad of op de Veluwe.
                        </p>
                    </Col>
                </Row>
                <StaticImage src={'../img/zeewolde/GettyImages-942591024@2x.png'} alt={'haven'}/>
                <div className={'my-5 text-primary'}>
                    <Media>
                        <FaQuoteLeft className={'display-3 mr-4'} />
                        <Media.Body>
                        <span className={'h3 text-primary'}>
                    Zeewolde heeft zoveel te bieden. <br/>
                    Ontdek het zelf maar!
                        </span>
                        </Media.Body>
                    </Media>
                </div>

                <div className={'my-5'}>
                <h6 className={"font-weight-bold"}>Een uitgekiende mix van winkelen en wonen</h6>
                <Row>
                    <Col md={6}>
                        <p>
                            Zeewolde kent veel voorzieningen en ontspanningsmogelijkheden. Het centrum biedt een uitgebreid aanbod van winkels voor uw dagelijkse benodigdheden. Maar ook als u ergens een hapje wilt eten of gezellig een drankje wilt doen, hoeft u niet ver te zoeken.
                        </p>
                    </Col>
                    <Col md={6}>
                        <p>Uiteraard is aan overige voorzieningen ook geen gebrek, met gezondheidscentra, basis- en voortgezet onderwijs en een rijk (sport)verenigingsleven. Het is in Zeewolde gewoon heel fijn wonen.</p>
                    </Col>
                </Row>
                </div>
            </Container>

            <Row noGutters>
                <Col md={6}>
                    <StaticImage src={'../img/zeewolde/GettyImages-1129092266@2x.png'} alt={'groen'} />
                </Col>
                <Col md={6}>
                    <StaticImage className={'ml-1'} src={'../img/zeewolde/GettyImages-1265904284@2x.png'} alt={'heide'} />
                    <StaticImage className={'ml-1'} src={'../img/zeewolde/GettyImages-514270087@2x.png'} alt={'water'} />
                </Col>
            </Row>

            <StaticImage
                src={'../img/zeewolde/Dode_Hond_Eiland@2x.png'}
                alt={'dode hond eiland'}
                layout={'fullWidth'}
            />
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "zeewolde"}) {
            childMarkdownRemark {
                ...Page
            }
        }
    }
`

